<template>
  <div>
    <template v-if="!showFeedbackForgotPassword">
      <h1 class="title-patternOne text-primary">Esqueceu sua senha?</h1>
      <p class="text-patternTwo color-gray1">
        Digite o endereço de e-mail usado no cadastro. Enviaremos uma mensagem
        com o link de redefinição da senha.
        <br /><br />
        <span class="color-black1">Atenção:</span> O link estará disponível
        apenas pelos próximos 10 minutos.
      </p>
      <b-form @submit.prevent="sendRedefinePasswordEmail()">
        <b-form-group>
          <b-form-input
            id="email"
            type="email"
            placeholder="Digite seu e-mail"
            size="lg"
            required
            v-model="formForgotPassword.emailOrUserName"
          ></b-form-input>
        </b-form-group>
        <error-message :error="feedbackGeneralError" />
        <b-form-group>
          <b-button
            type="submit"
            variant="secondary"
            size="lg"
            :disabled="loadingButton"
            ><b-spinner large v-if="loadingButton"></b-spinner
            ><span v-else>Enviar</span></b-button
          >
        </b-form-group>
      </b-form>
    </template>
    <template v-else>
      <div class="feedback-container">
        <b-icon icon="envelope" class="text-primary"></b-icon>
        <h1 class="title-patternOne text-primary">E-mail enviado</h1>
        <p class="text-patternTwo color-gray1">
          Enviamos as instruções sobre a redefinição da sua senha, verifique seu
          e-mail e siga as instruções.
        </p>
        <b-form-group>
          <b-button
            type="submit"
            variant="secondary"
            size="lg"
            @click="$router.back()"
            >Voltar para o início</b-button
          >
        </b-form-group>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    formForgotPassword: {
      emailOrUserName: '',
    },
    loadingButton: false,
    showFeedbackForgotPassword: false,
    feedbackGeneralError: {
      status: 0,
      message: [],
      errorDictionary: [],
      data: {},
    },
  }),
  methods: {
    sendRedefinePasswordEmail() {
      this.loadingButton = true;
      this.$onboardingService
        .forgotPassword(this.formForgotPassword)
        .then((response) => {
          this.showFeedbackForgotPassword = true;
        })
        .catch((error) => {
          this.feedbackGeneralError = error;
          this.loadingButton = false;
        });
    },
  },
  created() {
    if (this.$store.state.emailVerified)
      this.formForgotPassword.email = this.$store.state.emailVerified;
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 1rem;
}

p {
  text-transform: uppercase;
  margin-bottom: 2.75rem;
}

form {
  .btn {
    margin-top: 6rem;
  }
}

.feedback-container {
  margin-top: 8rem;
  .b-icon {
    font-size: 200px; //12.5rem
  }
  h1 {
    margin-top: 1.75rem;
  }
  .btn {
    margin-top: 5rem;
  }
}

@media (max-width: 768px) {
  .feedback-container {
    margin-top: 0;
    .b-icon {
      font-size: 140px;
    }
  }

  form {
    .btn {
      margin-top: 4rem;
    }
  }
}
</style>